import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';

const BlogPost = () => {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogRef = doc(db, "blogs", id);
        const blogSnapshot = await getDoc(blogRef);
        if (blogSnapshot.exists()) {
          setBlog(blogSnapshot.data());
          console.log(blogSnapshot.data());
        } else {
          console.warn("Blog not found!");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto py-12"
    >
      <img
        src={blog.imageSuggestions?.[0] || "/placeholder.jpg"}
        alt={blog.title}
        className="w-full h-64 object-cover mb-6"
      />
      <h1 className="text-heading font-heading text-4xl mb-4">{blog.title}</h1>
      <div className="prose max-w-none">
        {blog.content.map((item, index) => (
              <p key={index}>{item.text}</p> //Here is our Paragraph that I provieded in you the above chats, so use the function that you created here and update in this pls
            ))}
      </div>
    </motion.div>
  );
};

export default BlogPost;