import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import heroImage from '../../assets/hero-image.jpg';

function HeroSection() {
  const { scrollY } = useScroll();
  const scale = useTransform(scrollY, [0, 400], [1, 1.09]);
  const opacity = useTransform(scrollY, [0, 300], [1, 1]);

  return (
    <motion.section
      className="w-full flex flex-col lg:flex-row items-center justify-between py-10 lg:py-20 px-4 lg:px-20 min-h-screen bg-primary-100 rounded-3xl"
    >
      <motion.div
        className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-8"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h1
          className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-heading text-bg-100 mb-4"
          style={{ opacity }}
        >
          Modern healthcare for <span className="text-accent-200">mental wellness</span>
        </motion.h1>
        <motion.p
          className="text-base sm:text-lg text-bg-200 mb-6 lg:mb-8 mt-10"
          style={{ opacity }}
        >
          At Dopamine For Thought, we're crafting a new era of mental healthcare through the <span className="text-accent-300 font-bold">Support Groups</span>.
          Our mission is to provide a compassionate, inclusive space where individuals can openly discuss their
          mental health and access expert care from licensed professionals — without judgment.
        </motion.p>
        <a href="https://chat.whatsapp.com/F4WM6hnx36ZB7xkZmQKVgO" target="_blank" rel="noopener noreferrer">
          <motion.button
            className="w-full sm:w-auto bg-accent-100 text-bg-100 text-2xl font-bold mt-10 py-3 px-6 rounded-md hover:bg-opacity-90 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Join our community
          </motion.button>
        </a>
      </motion.div>
      <motion.div
        className="w-full lg:w-1/2 mt-8 lg:mt-0"
        initial={{ opacity: 0, x: 40 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        style={{ scale, opacity }}
      >
        <img src={heroImage} alt="Mental Health" className="rounded-lg shadow-xl w-full h-auto object-cover max-h-[70vh] lg:max-h-none" />
      </motion.div>
    </motion.section>
  );
}

export default HeroSection;