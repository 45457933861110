import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { db } from '../../firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VideoPresence = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const q = query(collection(db, 'video_presence'), orderBy('timestamp', 'desc'), limit());
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setVideos(items);
    };

    fetchVideos();
  }, []);

  return (
    <section className="py-12 justify-center">
      <h2 className="text-3xl font-heading font-semibold text-text-100 mb-6 text-center">VIDEO & AUDIO</h2>
      <br />
      <div className="w-full max-w-4xl mx-auto"> {/* Increased max width to 4xl */}
        <Carousel 
          showThumbs={false} 
          showStatus={false} 
          infiniteLoop 
          autoPlay 
          className="relative"
        >
          {videos.map((video) => (
            <motion.div 
              key={video.id} 
              className="relative h-full w-full"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="relative pb-[36.25%] h-0 overflow-hidden bg-black">
                <div dangerouslySetInnerHTML={{ __html: video.youtube_embed_code }} className="absolute top-0 left-0 w-full h-full" />
              </div>
              <div className="legend absolute bottom-0 left-0 right-0 bg-opacity-50 bg-gray-900 text-white p-4 rounded-b-lg">
                <h3 className="text-lg font-semibold">{video.title}</h3>
              </div>
            </motion.div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default VideoPresence;
