import React, { useState } from 'react';
import { motion } from "framer-motion";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { AnimatePresence } from 'framer-motion';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '', // Added phone number field
    service: '',
    message: '',
    flag: false
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await addDoc(collection(db, "contacts"), formData);
      setIsModalOpen(true); // Open the modal on success
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-xl sm:rounded-2xl md:rounded-3xl">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5, ease: "easeOut" }}
        >
          Ready to Take the First Step?
        </motion.h2>
        <motion.div
          className="bg-white bg-opacity-20 p-6 sm:p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Contact Us</h3>
              <p className="mb-2">
                <a href="tel:+919700168944" className="underline">📞 Phone: +91 97001 68944</a>
              </p>
              <p className="mb-2">
                📧 Email: <a href="mailto:madhu.vamsi19@gmail.com" className="underline">madhu.vamsi19@gmail.com</a>
              </p>
              <p className="mb-4">📍 Addresses:</p>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  <a
                    href="https://www.google.com/maps?ll=17.48925,78.503408&z=11&t=m&hl=en&gl=IN&gl=IN&mapclient=embed&cid=14181434610183267613"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Alwal Clinic
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps?ll=17.401739,78.499721&z=11&t=m&hl=en&gl=IN&gl=IN&mapclient=embed&cid=1389178772765863322"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Baghlingampally Clinic
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps?ll=17.362702,78.510787&z=11&t=m&hl=en&gl=IN&gl=IN&mapclient=embed&cid=15662029146274489509"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Saidabad Clinic
                  </a>
                </li>
                <li>
                  <a
                    href="https://maps.app.goo.gl/6M3Rxgo4Z7PfpifF7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Chaitanyapuri Clinic
                  </a>
                </li>
              </ul>
              <p className="text-sm">Our team is available 24/7 to answer your questions and provide support.</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              <h3 className="text-xl sm:text-2xl font-semibold mb-4">Schedule an Appointment</h3>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  className="w-full p-3 rounded text-gray-800"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  className="w-full p-3 rounded text-gray-800"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone Number (with country code)"
                  className="w-full p-3 rounded text-gray-800"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
                <select
                  name="service"
                  className="w-full p-3 rounded text-gray-800"
                  value={formData.service}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Service</option>
                  <option value="mental-health">Mental Health</option>
                  <option value="sexual-health">Sexual Health</option>
                  <option value="womens-health">Women's Health</option>
                  <option value="lgbtq-services">LGBTQIA+ Services</option>
                </select>
                <textarea
                  name="message"
                  placeholder="Your Message"
                  className="w-full p-3 rounded text-gray-800"
                  rows="4"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
                <button
                  type="submit"
                  className="bg-white text-green-500 px-6 py-2 rounded-full text-lg font-semibold hover:bg-green-100 transition-colors"
                >
                  Schedule Appointment
                </button>
              </form>
            </motion.div>
          </div>
        </motion.div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white text-black p-6 sm:p-8 rounded-lg max-w-sm mx-auto text-center"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <h2 className="text-xl font-semibold mb-4">Thank You!</h2>
              <p className="mb-4">
                Our Squad will contact you in 24-48 hours. Please check your email log for the appointment details.
              </p>
              <p className="mb-4">
                You can also call or WhatsApp us at <a href="https://wa.me/+919700168944" className="underline">+91 97001 68944</a> to know the status of your appointment.
              </p>
              <p className="mb-4">
                📞 Phone: <a href="tel:+919700168944" className="underline">+91 97001 68944</a>
              </p>
              <button
                onClick={closeModal}
                className="bg-green-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-green-600 transition-colors"
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
