import React from 'react';
import { motion } from 'framer-motion';

// Import your hero image
// import heroImage from '../../assets/anger_management.jpg'; // Make sure to add this image to your assets folder

function DiagnosticHero() {
  return (
    <section className="relative w-full min-h-screen flex items-center justify-center overflow-hidden rounded-b-2xl">
      {/* Background Image */}
      <div 
        className="absolute inset-0 z-0 w-full h-full"
        style={{
          // backgroundImage: `url(${heroImage})`,
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          // backgroundRepeat: 'no-repeat',
          // filter: 'brightness(0.7)'
          background: 'linear-gradient(135deg, #019b98 0%, #55ccc9 50%, #c1ffff 100%)',
        }}
      />

      {/* Content Overlay */}
      <div className="relative z-10 text-white w-full max-w-4xl mx-auto px-4 text-center">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-4xl lg:text-5xl font-bold italic mb-8 text-primary-300"
        >
          "In every <span className="text-accent-100">diagnosis</span>, there is hope for healing."
        </motion.h2>

        <motion.h1 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6"
        >
          Diagnostic Services
        </motion.h1>

        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="text-lg mb-8"
        >
          Whether you're dealing with anxiety, depression, sleep issues, or more complex conditions like OCD or phobias, our experienced team guides you every step of the way. Using advanced psychological assessments and tailored therapeutic techniques, we ensure each diagnosis is precise and focused on your unique needs. We are here to help you find the right path to recovery and personal growth.
        </motion.p>

        <motion.a
          href="https://tally.so/r/wa0kBv" // Replace with your actual link
          target="_blank" rel="noopener noreferrer"
          className="bg-accent-100 text-white font-bold py-3 px-6 rounded-lg shadow-lg text-lg hover:bg-opacity-90 transition-colors inline-block"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Schedule an Assessment
        </motion.a>

      </div>
    </section>
  );
}

export default DiagnosticHero;