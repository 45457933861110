import React from 'react';
import Slider from "react-slick";
import { team } from "../../data/data";
import { FaInstagram, FaYoutube, FaLinkedin, FaFacebook } from 'react-icons/fa';

const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  customPaging: i => (
    <div className="w-2 h-2 md:w-3 md:h-3 bg-white rounded-full"></div>
  ),
  appendDots: dots => (
    <div className="absolute bottom-2 md:bottom-4 lg:bottom-6 w-full flex justify-center">{dots}</div>
  )
};

export default function Team() {
  const iconMap = {
    Instagram: <FaInstagram style={{ color: '#E1306C' }} />,
    YouTube: <FaYoutube style={{ color: '#FF0000' }} />,
    LinkedIn: <FaLinkedin style={{ color: '#0A66C2' }} />,
    Facebook: <FaFacebook style={{ color: '#1877F2' }} />
  };

  return (
    <div className="bg-gradient-to-r from-blue-400 to-teal-500 text-white p-4 sm:p-6 md:p-8 lg:p-10 rounded-lg sm:rounded-xl md:rounded-2xl lg:rounded-3xl mx-2 sm:mx-4 md:mx-6 lg:mx-8">
      <div className="relative max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6 md:mb-8 lg:mb-10 text-center">The Squad</h2>
        <Slider {...settings}>
          {team.map((member, index) => (
            <div key={member.name} className="bg-white bg-opacity-20 p-3 sm:p-4 md:p-6 lg:p-8 rounded-lg text-center">
              <img
                src={member.image}
                alt={member.name}
                className="h-24 w-24 sm:h-32 sm:w-32 md:h-40 md:w-40 lg:h-48 lg:w-48 xl:h-56 xl:w-56 rounded-full mx-auto mb-3 sm:mb-4 object-cover"
              />
              <h3 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-1 sm:mb-2">{member.name}</h3>
              <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium mb-2 sm:mb-3">{member.role}</p>
              {member.socialMedia && (
                <div className="flex justify-center space-x-2 sm:space-x-3 md:space-x-4 lg:space-x-5">
                  {member.socialMedia.map((social, idx) => (
                    <a
                      key={idx}
                      href={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-300 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl hover:text-blue-500 transition-colors duration-300"
                    >
                      {iconMap[social.platform]}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}