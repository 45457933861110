import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
// import AdminRoute from './components/AdminRoute';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './pages/Home';
import Service from './pages/Service';
import Diagnostic from './pages/Diagnostic';
import OurTeam from './pages/OurTeam';
import Business from './pages/Business';
import Loader from './components/Loader';
import PamphletPopup from './components/home/PamphletPopup';
import ServicePage from './components/diagnostics/ServicePage';
import GiftAFriend from './pages/GiftAFriend';
import Counselling from './components/services/Counselling';
import Neuromodulation from './components/services/Neuromodulation';
import Medications from './components/services/Medications';
import Lab from './components/services/Lab';
import Tooltips from './components/Tooltips';
import MediaPage from './pages/Media';
import SmoothScroll from './layouts/SmoothScroll';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';


function App() {
  const [loading, setLoading] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check sessionStorage for popup visibility
    const popupState = sessionStorage.getItem('popupVisible');
    if (popupState !== null) {
      setIsPopupVisible(JSON.parse(popupState));
    }
  }, []);

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    sessionStorage.setItem('popupVisible', JSON.stringify(false));
  };

  if (loading) return <Loader />;

  return (
    <AuthProvider>
    <Router>
      <SmoothScroll>
      {isPopupVisible && <PamphletPopup onClose={handlePopupClose} />}
      <div className="flex flex-col min-h-screen">
        <Header />
        <Tooltips />
        <main className="flex-grow container mx-auto px-4 py-8 pt-20">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/diagnostics" element={<Diagnostic />} />
            <Route path="/gift-a-friend" element={<GiftAFriend />} />
            <Route path="/service/:id" element={<ServicePage />} />
            <Route path="/services" element={<Service />} />
            <Route path="/services/counselling" element={<Counselling />} />
            <Route path="/services/neuromodulation" element={<Neuromodulation />} />
            <Route path="/services/medications" element={<Medications />} />
            <Route path="/services/lab" element={<Lab />} />
            <Route path="/for-business" element={<Business />} />
            <Route path="/Squad" element={<OurTeam />} />
            <Route path="/Media" element={<MediaPage />} />

            {/* New blog routes */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
        </main>
        <Footer />
      </div>
      </SmoothScroll>
    </Router>
    </AuthProvider>
  );
}

export default App;
