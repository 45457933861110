import React from 'react';
import { motion } from 'framer-motion';

const des = {
  'Workplace Wellness': 'Companies that invest in human capital have clear benefits associated with workplace wellness. One will find that happy employees tend to perform better, actively take up work initiatives, improve productivity, and manage time efficiently.',
  'Student Wellness': 'Medical literature shows that targeting mental health issues at a young age can result in better outcomes later in life. Youth are also essential to the dissemination of information and change in the perceptions of mental health.',
};

function BusinessIntro({ variants, name }) {
  return (
    <motion.section
      variants={variants}
      className="bg-primary-200 text-text-100 p-8 rounded-lg shadow-lg"
    >
      <h2 className="text-3xl font-bold mb-4 text-accent-300">{name}</h2>
      <p className="text-xl">
        {des[name] || des['Workplace Wellness']}
      </p>
    </motion.section>
  );
}

export default BusinessIntro;
